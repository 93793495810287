import { HttpParams } from '@angular/common/http';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from '@components';
import {  Report } from '@models';
import { TranslateService } from '@ngx-translate/core';
import { ComProductService, GlobalAlertService, MarketContextService, PointService, ReportService, VenService } from '@services';
import { GRANULARITIES } from 'src/constants';
import * as uuid from 'uuid';
// import { specifierIdError } from './common/customErrors';
import { EditReportComponent } from './edit-report';

@Component({
  selector: 'leviathan-jr-create-report',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class VtnReportEditViewComponent extends EditReportComponent implements OnInit, OnDestroy {
  reports: Partial<Report>[];
  TYPES: string[] = ['x-METADATA_OFFERS'];
  GRANULARITY = GRANULARITIES;
  columnsToDisplay = [
    'rID',
    'readingType',
    'reportType',
    'reportDataSource',
    // 'samplingRate',
    'marketContext',
    'actions',
  ];
  descriptionsReport: Report = {
    report_request_id: '',
    specifier_id: '',
    type: '',
    id_map: {},
    ven_id: 0,
    granularity: 0,
    report_back_duration: 0,
    start_dttm: '',
    end_dttm: '',
    descriptions: [],
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    public dialog: MatDialog,
    reportService: ReportService,
    fb: UntypedFormBuilder,
    venService: VenService,
    productService: ComProductService,
    messageService: GlobalAlertService,
    translateService: TranslateService,
    marketContextService: MarketContextService,
    pointService: PointService,
  ) {
    super(reportService, fb, venService, productService, messageService, translateService,
      marketContextService, pointService
    );
  }

  ngOnInit(): void {
    this.submitting = true;
    this.makeReportForm();
    this.makeDescriptionForm();
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.ven.id = params['venId'];
        this.report.id = params['id'];
      }),
      this.getVens(),
    );
    this.fetchReports();
    this.getList(this.marketService);
  }
  makeReportForm() {
    this.reportForm = new UntypedFormGroup({
      type: new UntypedFormControl(this.TYPES[0], Validators.required),
      specifierId: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      granularity: new UntypedFormControl(GRANULARITIES[5].value, Validators.required),
    });
  }
  makeDescriptionForm() {
    this.descriptionForm = this.fb.group({
      description: this.fb.array([this.addItem()]),
    });
  }

  handleVenCancel() {
    if (this.ven.id) {
      this.router.navigate([`/vens`, this.ven.id]);
      this.dialog.closeAll();
    } else {
      this.router.navigate([`/vens`]);
    }
  }

  openCancelReportCreation() {
    this.submitting = false;
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant(`reports.cancelCreation`),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => this.handleVenCancel(),
        firstButtonCallback: () => this.dialog.closeAll(),
      },
    });
  }

  openCancelOrAcceptDialog() {
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant(`vtn-reports.save.verification`),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => this.callBack(),
        firstButtonCallback: () => this.dialog.closeAll(),
      },
    });
  }
  callBack() {
    this.submitting = true;
    try {
      // Assemble payload
      const payload = this.reportForm.getRawValue();
      const descriptionPayload = this.descriptionForm.getRawValue();

      for (const desc of descriptionPayload.description) {
        if (this.ven.groups.length > 0) {
            desc.reportDataSource = {groupID: desc.reportDataSource};
        } else {
            desc.reportDataSource = {resourceID: desc.reportDataSource};
        }
      }

      let venSub;

      if (!this.report.id) {
        venSub = this.save(payload, descriptionPayload);
      } else {
        venSub = this.put(payload, descriptionPayload);
      }
      this.subscriptions.push(venSub);
    } finally {
      this.submitting = false;
      this.dialog.closeAll();
    }
  }
  save(payload, descriptionPayload){
    const bodyToPost: Report = {
      report_request_id: uuid.v4(),
      specifier_id: payload.specifierId,
      type: payload.type,
      id_map: {},
      ven_id: Number(this.ven.id),
      granularity: payload.granularity,
      report_back_duration: 0,
      start_dttm: new Date().toISOString(),
      end_dttm: null,
      descriptions: descriptionPayload?.description,
      archived: false,
      created_dttm: new Date().toISOString(),
      direction: 'vtnToVen',
    };
    bodyToPost.descriptions.forEach(d => d.samplingRate = {maxRate: payload.granularity, minRate: payload.granularity, onChange: false});

    let reportCallPost = this.reportService.createReport$(bodyToPost);
    return reportCallPost.subscribe({
      next: (report) => {
        this.ngZone.run(() => {
          this.messageService.setSuccess(this.SUCCESS);
          this.router.navigateByUrl(`vens/${this.ven.id}`);
        });
      },
      error: () => {
        this.messageService.setError(this.BAD_REQUEST);
      },
    });
  }
  put(payload, descriptionPayload) {
    const bodyToPut: Report = {
      ...this.reportEdit,
      report_request_id: this.reportEdit.report_request_id,
      specifier_id: payload.specifierId,
      type: payload.type,
      id_map: this.reportEdit.id_map,
      ven_id: this.reportEdit.ven_id,
      granularity: payload.granularity,
      report_back_duration: this.reportEdit.report_back_duration,
      start_dttm: this.reportEdit.start_dttm,
      end_dttm: this.reportEdit.end_dttm,
      descriptions: descriptionPayload.description,
    };
    bodyToPut.descriptions.forEach(d => d.samplingRate = {maxRate: payload.granularity, minRate: payload.granularity, onChange: false});
    let reportCallPut = this.reportService.editReportById$(this.report.id, bodyToPut);
    return reportCallPut.subscribe({
      next: (report) => {
        this.ngZone.run(() => {
          this.messageService.setSuccess(this.SUCCESS);
          this.router.navigateByUrl(`vens/${this.ven.id}`);
        });
      },
      error: () => {
        this.messageService.setError(this.BAD_REQUEST);
      },
    });
  }
  fetchReports() {
    const params = new HttpParams({ fromObject: { per_page: 1000, ven_id: this.ven.id } });
    this.reportService.getPage(params).then((res) => {
      this.reports = res.data;
      // TODO among many other items is to restore this check. Currently it doesn't check
      // if the reportSpecifier that it thinks pre-exists is actually for the current report.
      // this.reportForm.get('specifierId').addValidators([specifierIdError(this.reports)]);
    });
  }
  reportActionSuccess(message) {
    const msg = this.translateService.instant(message);
    this.messageService.setSuccess(msg);
    this.fetchReports();
    this.dialog.closeAll();
  }

  openArchiveReportDialog(report: Report) {
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant('reports.archive.confirm'),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => {
          this.reportService
            .update(report.id, { archived: true })
            .toPromise()
            .then(() => this.reportActionSuccess('reports.archive.success'));
        },
        firstButtonCallback: () => this.dialog.closeAll(),
      },
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subs: any) => (subs ? subs.unsubscribe() : ''));
    this.unsubscribe.unsubscribe();
  }
}
