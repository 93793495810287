import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { VEN } from '@models';
import { BaseTableViewComponent } from '../../common/base-table-view';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '@components';
import { GlobalAlertService, VenService } from '@services';

@Component({
  selector: 'ven-list-view',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class VenListViewComponent extends BaseTableViewComponent implements OnInit, OnDestroy {
  BAD_REQUEST = 'Oops, There was a problem with your request';
  VENS_SUCCESS = 'Ven was deleted successfully';
  VENS_SUCCESS_DISABLED = 'Ven has been successfully disabled';
  VENS_SUCCESS_RESTORED =  'Ven has been successfully restored';
  subscriptions: Subscription[] = [];
  columnsToDisplay = ['ven', 'registration', 'resource', 'group', 'market', 'actions', 'status'];

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private venService: VenService,
    private messageService: GlobalAlertService,
  ) {
    super();
    this.BAD_REQUEST = this.translateService.instant('notification.bad_request');
    this.VENS_SUCCESS = this.translateService.instant('vens.delete.notification.success');
    this.VENS_SUCCESS_DISABLED = this.translateService.instant('vens.disable.notification.success');
    this.VENS_SUCCESS_RESTORED = this.translateService.instant('vens.restore.notification.success');
  }

  ngOnInit() {
    this.initialize(this.route);
  }

  override dataCall(qp) {
    this.router.navigate(['vens'], {replaceUrl: true, queryParams: qp});
    return this.venService.getPage(qp);
  }

  deleteVen(ven: VEN) {
    try {
      const deleteVenSub = this.venService.deleteVen$(ven.id).subscribe({
        next: () => {
          this.messageService.setSuccess(this.VENS_SUCCESS);
          this.loadData();
        },
        error: () => {
          this.messageService.setError(this.BAD_REQUEST);
        },
      });
      this.subscriptions.push(deleteVenSub);
    } finally {
      this.closeOutDialogs();
    }
  }

  openDeleteDialog(ven: VEN) {
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant('vens.dialog.delete'),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => this.deleteVen(ven),
        firstButtonCallback: () => this.closeOutDialogs(),
      },
    });
  }

  openRestoreDialog(ven: VEN) {
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant('vens.dialog.restore'),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => {
          this.loadData();
          this.closeOutDialogs();
        },
        firstButtonCallback: () => this.closeOutDialogs(),
      },
    });
  }

  openDisableDialog(ven: VEN) {
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant('vens.dialog.disable'),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => {
          //Move to function and actually update in DB
          this.closeOutDialogs();
            this.loadData();
        },
        firstButtonCallback: () => this.closeOutDialogs(),
      },
    });
  }

  closeOutDialogs() {
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
  }
}
